import React, { useEffect, useState, useContext } from "react";
import { useDataLogFetchMutation } from "../../../features/dataLog/dataLogApi";
import { ThemeContext } from "../../../context/ThemeContext";
import { useTotalRoomFetchQuery } from "../../../features/dataLog/filters/filterApi";
import { useTotalSensorListFetchQuery } from "../../../features/dataLog/filters/filterApi";
import { useAllNotificationFetchMutation } from "../../../features/notification/notificationApi";

import { useGeneratorDataFetchMutation } from "../../../features/generator/generatorDataLogApi";
import { useGetGeneratorListQuery } from "../../../features/generator/generatorDataLogApi";

import AllEnergyMeterDataLog from "./AllEnergyMeterDataLog";
import useTitle from "../../../hooks/useTitle";

import { useEnergyMeterLatestDataMutation } from "../../../features/energy-meter/energyMeterUpdatedApi";
import { useEnergyMeterListQuery } from "../../../features/energy-meter/energyMeterUpdatedApi";

function EnergyMeterDataLogMain() {
  useTitle("Energy Consumption Log");
  const theme = useContext(ThemeContext);

  //   const [allNotificationFetch, { isLoading }] =
  //     useAllNotificationFetchMutation();
  // const { data: generatorListResponse, isLoading: generatorListLoading } =
  //   useGetGeneratorListQuery();

  const { data: energyMeterList, isLoading: energyMeterListLoading } =
    useEnergyMeterListQuery();

  // const [generatorDataFetch, { isLoading }] = useGeneratorDataFetchMutation();
  const [energyMeterLatestData, { isLoading }] =
    useEnergyMeterLatestDataMutation();

  const [tableAllData, setTableAllData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataView, setDataView] = useState(10);
  const [formData, setFormData] = useState({});

  const [tableLoading, setTableLoading] = useState(true);

  // const [date, setDate] = useState({
  //   startdate: null,
  //   enddate: null,
  // });

  let totalDataCount = 0;
  let tableData = [];
  let element = "";

  useEffect(() => {
    const initialObject = { dataview: dataView, page: activePage };
    setTableLoading(true);
    energyMeterLatestData({ ...initialObject, ...formData })
      .unwrap()
      .then((payload) => setTableAllData(payload))
      .catch((error) => {
        console.log(error);
      });
  }, [activePage, dataView]);

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  const searchSumbitHandler = (e) => {
    e.preventDefault();

    // console.log(formData);
    energyMeterLatestData(formData)
      .unwrap()
      .then((payload) => setTableAllData(payload))
      .catch((error) => {
        console.log(error);
      });
    // if (date?.startdate && room_id && sensor_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     room_id: room_id,
    //     sensor_id: sensor_id,
    //   });
    // } else if (date?.startdate && room_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     room_id: room_id,
    //   });
    // } else if (date?.startdate && sensor_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     sensor_id: sensor_id,
    //   });
    // } else {
    //   dataLogFetch(date);
    // }

    // console.log(fetchedData);
  };

  if (!isLoading) {
    // console.log("Data Loaded");
    // console.log(tableAllData);
    const data = tableAllData.data || [];

    tableData = data.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();

      return {
        ...item,
        created_at: formattedDate,
      };
    });

    totalDataCount = tableAllData?.total || 0;

    // if (tableData.length > 0) {
    //   element = (
    //     <AllNotification
    //       tableData={tableData}
    //       setActivePage={setActivePage}
    //       setDataView={setDataView}
    //       activePage={activePage}
    //       totalDataCount={totalDataCount}
    //     />
    //   );
    // } else {
    //   element = <div>No Data Found</div>;
    // }
  }

  const formChangeHandler = (e) => {
    if (e.target.value == "") {
      delete formData[e.target.name];
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const downloadCSVHandler = (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_API_URL + "/apiV2";
    const exportURL = `${baseUrl}/energy-meter-excel-export`;

    if (formData?.startdate && formData?.combined_id) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${formData?.startdate}&enddate=${formData?.enddate}&combined_id=${formData?.combined_id}`;
      window.location.replace(exportUrlWithDate);
    } else if (formData?.startdate) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${formData?.startdate}&enddate=${formData?.enddate}`;
      window.location.replace(exportUrlWithDate);
    } else {
      window.location.replace(exportURL);
    }
  };

  return (
    <div className={`${theme}`}>
      <div className="row">
        <div
          className="theme-text"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "1rem",
          }}
        >
          Energy Consumption Log
        </div>
        <div className="col-md-10">
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              <div className="form-group col-md-2">
                <label className="theme-text">Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="startdate"
                  onChange={formChangeHandler}
                  required
                />
              </div>
              <div class="form-group col-md-2">
                <label className="theme-text">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="enddate"
                  onChange={formChangeHandler}
                  required
                />
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="combined_id"
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select Meter
                  </option>
                  {energyMeterList?.data?.map((energyInfo) => {
                    return (
                      <option value={energyInfo?.combined_id}>
                        {energyInfo?.meter_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <button
                  // type="submit"
                  class="btn btn-info col-md-12"
                  onClick={(e) => downloadCSVHandler(e)}
                >
                  {" "}
                  Download CSV
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* {element} */}
      <AllEnergyMeterDataLog
        tableData={tableData}
        setActivePage={setActivePage}
        setDataView={setDataView}
        activePage={activePage}
        totalDataCount={totalDataCount}
        tableLoading={tableLoading}
      />
    </div>
  );
}

export default EnergyMeterDataLogMain;
