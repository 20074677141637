import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import useTitle from "../../../hooks/useTitle";

import { ThemeContext } from "../../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";
import getBase64 from "../../../helper/helper";

import { useGetCompanyListQuery } from "../../../features/aman-dashboard/amanCompanyCreateApi";

import { useGetCompanyAssignListQuery } from "../../../features/aman-dashboard/amanCompanyAssignApi";
import { useGetGeneratorListForInfoUpdateQuery } from "../../../features/generator/generatorInfoUpdateApi";
import { useEnergyMeterListQuery } from "../../../features/energy-meter/energyMeterUpdatedApi";
import { useCompanyAssignMutation } from "../../../features/aman-dashboard/amanCompanyAssignApi";
import { useCompanyAssignUpdateMutation } from "../../../features/aman-dashboard/amanCompanyAssignApi";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function CompanyAssign() {
  useTitle("Company Assign");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedDeviceType, setSelectedDeviceType] = useState("");
  const [selectedDeviceData, setSelectedDeviceData] = useState([]);

  const { data: companyList, isLoading: companyListLoading } =
    useGetCompanyListQuery();
  const { data: companyAssignList, isLoading } = useGetCompanyAssignListQuery();
  const { data: generatorList } = useGetGeneratorListForInfoUpdateQuery();
  const { data: energyMeterList } = useEnergyMeterListQuery();

  const [companyAssign] = useCompanyAssignMutation();
  const [companyAssignUpdate] = useCompanyAssignUpdateMutation();

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  if (!isLoading) {
    if (companyAssignList.success) {
      defaultData = [...companyAssignList?.data];
      defaultData = defaultData?.map((companyInfo) => {
        const data = new Date(companyInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...companyInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const handleClose = () => {
    setShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      companyAssign(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      companyAssignUpdate(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};
    setUpdateFormData(formData);
    // console.log(formData);
    if (formData?.device_type == "generator") {
      setSelectedDeviceType("generator");
      setSelectedDeviceData(generatorList?.data);
    } else {
      setSelectedDeviceType("em");
      setSelectedDeviceData(energyMeterList?.data);
    }
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change

    if (e.target.name === "image") {
      getBase64(e.target.files[0])
        .then((base64image) => {
          setUpdateFormData((previousValue) => ({
            ...previousValue,
            [e.target.name]: base64image,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUpdateFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const deviceTypeChangeHandler = (e) => {
    // console.log(e.target.value);
    // console.log(generatorList?.data);
    // console.log("erergyMeter");
    // console.log(energyMeterList?.data);
    if (e.target.value == "generator") {
      setSelectedDeviceType("generator");
      setSelectedDeviceData(generatorList?.data);
    } else {
      setSelectedDeviceType("em");
      setSelectedDeviceData(energyMeterList?.data);
    }
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Assign Company
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector
                ? "Assign Company"
                : "Update Assigned Company"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    {/* <div className="form-group">
                      <label>
                        Company Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        name="name"
                        defaultValue={updateFormData?.name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div> */}

                    <div class="form-group">
                      <label class="form-label" for="premises no">
                        Select Company<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="company_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.company_id || ""}
                      >
                        <option label="Choose one"></option>
                        {companyList?.data?.map((companyInfo) => {
                          return (
                            <option value={companyInfo?.id}>
                              {companyInfo?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="premises no">
                        Select Device Type
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="device_type"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                          deviceTypeChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.device_type || ""}
                      >
                        <option label="Choose one"></option>
                        <option value="em">Energy Meter</option>
                        <option value="generator">Generator</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-label">
                        Select Device<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="device_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.device_id || ""}
                      >
                        <option label="Choose one"></option>
                        {selectedDeviceData?.map((deviceInfo) => {
                          return (
                            <option value={deviceInfo?.id}>
                              {selectedDeviceType == "generator"
                                ? deviceInfo?.name
                                : deviceInfo?.meter_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Company Name</HeaderCell>
              <Cell dataKey="company_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Address</HeaderCell>
              <Cell dataKey="company_address" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device Name</HeaderCell>
              <Cell dataKey="device_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device Type</HeaderCell>
              <Cell dataKey="device_type" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device Code</HeaderCell>
              <Cell dataKey="device_code" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyAssign;
