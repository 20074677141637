import React, { useContext, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ThemeContext } from "../../../context/ThemeContext";

function EnergyMeterGauge({ value, threshold, unit }) {
  const theme = useContext(ThemeContext);
  const sensor_min = threshold[0].sensor_min;
  const sensor_max = threshold[0].sensor_max;
  let strokeColor = "red";
  const calcThresholdColor = (threshold, value) => {
    const { min, max } = threshold[0];
    if (value < min || value > max) {
      strokeColor = "red";
    }
    if (value >= min && value <= max) {
      strokeColor = "green";
    }
  };
  calcThresholdColor(threshold, value);
  return (
    <div className={`bg-white ${theme}`}>
      <div className="gauge theme-gauge-background-color">
        <CircularProgressbar
          value={value}
          text={`${value} ${unit}`}
          minValue={sensor_min}
          maxValue={sensor_max}
          circleRatio={0.7} /* Make the circle only 0.7 of the full diameter */
          styles={{
            trail: {
              strokeLinecap: "butt",
              transform: "rotate(-126deg)",
              transformOrigin: "center center",
            },
            path: {
              strokeLinecap: "butt",
              transform: "rotate(-126deg)",
              transformOrigin: "center center",
              // stroke: calcColor(value, 0, 120),
              stroke: strokeColor,
            },
            text: {
              fill: "#ddd",
            },
          }}
          strokeWidth={10}
        />{" "}
      </div>{" "}
    </div>
  );
}

export default EnergyMeterGauge;
