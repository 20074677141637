// import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import { useContext } from "react";
import { Table, Pagination } from "rsuite";

import { useGetGeneratorListQuery } from "../../../features/generator/generatorDataLogApi";

import { useGetOilConsuptionReportMutation } from "../../../features/generator/generatorReportApi";
import useTitle from "../../../hooks/useTitle";

const { Column, HeaderCell, Cell } = Table;

function OilConsumptionReport() {
  useTitle("Operating Report");
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [dateWiseData, setDateWiseData] = useState([]);
  const [totalData, setTotalData] = useState({});

  const [tableLoading, setTableLoading] = useState(true);

  // const [getDateWiseOilConsumption, { isLoading: dateWiseLoading }] =
  //   useGetDateWiseOilConsumptionMutation();

  const [getOilConsuptionReport, { isLoading: dateWiseLoading }] =
    useGetOilConsuptionReportMutation();

  const { data: generatorListResponse, isLoading: generatorListLoading } =
    useGetGeneratorListQuery();

  useEffect(() => {
    getOilConsuptionReport()
      .unwrap()
      .then((payload) => {
        setDateWiseData(payload.data);
        setTotalData({
          total_oil_consumption: payload?.total_oil_consumption,
          total_duration_time: payload?.total_duration_time,
          total_engin_runtime: payload?.total_engin_runtime,
          max_temp: payload?.max_temp,
          max_battery_voltage: payload?.max_battery_voltage,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (!dateWiseLoading) {
      setTableLoading(false);
    }
  }, [dateWiseLoading]);

  let defaultData = [];
  let data = [];

  if (!dateWiseLoading) {
    defaultData = dateWiseData?.map((alertInfo) => {
      const data = new Date(alertInfo.updated_at);
      const formattedDate = data.toLocaleString();
      return {
        ...alertInfo,
        updated_at: formattedDate,
      };
    });

    // data = defaultData?.filter((v, i) => {
    //   const start = limit * (page - 1);
    //   const end = start + limit;
    //   return i >= start && i < end;
    // });

    data = [...defaultData];
  }

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    setPage(1);
    console.log(formData);
    getOilConsuptionReport(formData)
      .unwrap()
      .then((payload) => {
        setDateWiseData(payload.data);
        setTotalData({
          total_oil_consumption: payload?.total_oil_consumption,
          total_duration_time: payload?.total_duration_time,
          total_engin_runtime: payload?.total_engin_runtime,
          max_temp: payload?.max_temp,
          max_battery_voltage: payload?.max_battery_voltage,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const totalRow = {
    name: "Total",
    duration: `Total Runtime : ${totalData?.total_duration_time}`,
    total_oil_out: `Total Oil Consumption : ${totalData?.total_oil_consumption}`,
    engine_runtime: `Total Engine Runtime : ${totalData?.total_engin_runtime}`,
    temp: `Max Temp : ${totalData?.max_temp}`,
    battery_voltage: `Max Battery Voltage : ${totalData?.max_battery_voltage}`,
  };
  const columns = [
    {
      name: "name",
      title: "Name",
    },
    {
      name: "startdate",
      title: "Start Time",
    },
    {
      name: "enddate",
      title: "End Time",
    },
    {
      name: "duration",
      title: "Runtime",
    },
    {
      name: "total_oil_out",
      title: "Oil Consumption",
    },
    // {
    //   name: "engine_runtime",
    //   title: "Engine Runtime",
    // },
    {
      name: "temp",
      title: "Max Temperature",
    },
    {
      name: "battery_voltage",
      title: "Max Battery Voltage",
    },
  ];

  const downloadCSVHandler = (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_API_URL + "/apiV2";
    const exportURL = `${baseUrl}/generator/oil-consumption-pdf`;
    if (formData?.startdate && formData?.combined_id) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${formData?.startdate}&enddate=${formData?.enddate}&combined_id=${formData?.combined_id}`;
      window.location.replace(exportUrlWithDate);
    } else if (formData?.startdate) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${formData?.startdate}&enddate=${formData?.enddate}`;
      window.location.replace(exportUrlWithDate);
    } else {
      window.location.replace(exportURL);
    }
  };

  return (
    <div className={` ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Operating Report
      </div>
      <div
        className="col-md-10"
        style={{ marginLeft: "-10px", marginBottom: "1rem" }}
      >
        <form onSubmit={(e) => searchSumbitHandler(e)}>
          <div className="row">
            <div className="form-group col-md-2">
              <label className="theme-text">Start Date </label>
              <input
                type="datetime-local"
                className="form-control"
                name="startdate"
                onChange={dataChangeHandler}
                required
              />
            </div>
            <div class="form-group col-md-2">
              <label className="theme-text">End Date</label>
              <input
                type="datetime-local"
                class="form-control"
                name="enddate"
                onChange={dataChangeHandler}
                required
              />
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <select
                className="form-select"
                aria-label="Default select example"
                name="combined_id"
                onChange={dataChangeHandler}
              >
                <option value="" selected>
                  Select Generator
                </option>
                {generatorListResponse?.data?.map((generatorInfo) => {
                  return (
                    <option value={generatorInfo?.unique_id}>
                      {generatorInfo?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <button type="submit" class="btn btn-primary col-md-12">
                Submit
              </button>
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <button
                class="btn btn-info col-md-12"
                onClick={downloadCSVHandler}
              >
                Export Pdf
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="theme-background theme-text">
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={[...data]} virtualized>
            {columns.map((column) => (
              <Column
                key={column.name}
                width={100}
                // resizable
                align="center"
                flexGrow={1}
                fullText
              >
                <HeaderCell>{column.title}</HeaderCell>
                <Cell dataKey={column.name} />
              </Column>
            ))}
          </Table>
          <div className="total-row" style={{ background: "transparent" }}>
            {columns.map((column) => (
              <div
                key={column.name}
                className="total-cell theme-background theme-text"
                style={{ width: "200px", border: "none", fontWeight: "bold" }}
              >
                {column.name === "name" ? "Total" : totalRow[column.name]}
              </div>
            ))}
          </div>
          {/* <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData?.length}
              limitOptions={[10, 30, 50, 100, 200]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OilConsumptionReport;
