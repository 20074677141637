import React, { useEffect, useState, useContext } from "react";
// import "./style/dataLog.css";
import { Table, Pagination, Tag, IconButton } from "rsuite";
import { ThemeContext } from "../../../context/ThemeContext";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
const { Column, HeaderCell, Cell } = Table;

// const defaultData = mockUsers(100);

const rowKey = "id";
const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
);

const AllGeneratorDataLog = ({
  tableData,
  setActivePage,
  setDataView,
  activePage,
  totalDataCount,
  tableLoading,
}) => {
  const theme = useContext(ThemeContext);
  const [limit, setLimit] = React.useState(10);

  // const [page, setPage] = React.useState(4);
  const [totalData, setTotalData] = useState(totalDataCount);

  const handleChangeLimit = (dataKey) => {
    setLimit(dataKey);
    setActivePage(1);
    setDataView(dataKey);
  };
  const setActivePageHandler = (dataKey) => {
    setActivePage(dataKey);
  };
  useEffect(() => {
    setTotalData(totalDataCount);
  }, [totalDataCount]);

  let data = [...tableData];
  const loadingHandler = () => {
    // return true;
    // return setTimeout(myGreeting, 100);
    // function myGreeting(){
    //   return true;
    // }
    return false;
  };

  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };
  const renderRowExpanded = (rowData) => {
    return (
      <div className={`${theme}`}>
        {/* <div
          style={{
            width: 60,
            height: 60,
            float: "left",
            marginRight: 10,
            background: "#eee",
          }}
        >
          <img src={rowData.avatar} style={{ width: 60 }} />
        </div> */}
        <p>Main Device : {rowData.main_device}</p>
        <p>Number of Starts : {rowData.number_of_starts}</p>
        <p>Positive KWH : {rowData.positive_kwh}</p>
        <p>KVaH : {rowData.kvah}</p>
        <p>KVarH : {rowData.kvarh}</p>
        <p>Line Voltage : {rowData.line_voltage}</p>
        <p>Line Current : {rowData.line_current}</p>
        <p>Line Watts : {rowData.line_watts}</p>
        <p>Line VA : {rowData.line_va}</p>
        <p>Line VAR : {rowData.line_var}</p>
        <p>Line PF : {rowData.line_pf}</p>
        <p>Average PF : {rowData.average_pf}</p>
      </div>
    );
  };

  return (
    <div className="theme-background theme-text">
      <Table
        // loading={tableData.length > 0 ? false : true}
        loading={tableLoading ? true : false}
        height={500}
        data={data}
        renderEmpty={() => {
          return <div className="rs-table-body-info">No Data Available</div>;
        }}
        rowKey={rowKey}
        expandedRowKeys={expandedRowKeys}
        onRowClick={(data) => {
          //   console.log(data);
        }}
        renderRowExpanded={renderRowExpanded}
        rowExpandedHeight={340}
        // autoHeight
        // wordWrap
        // className={`${theme} `}
        
      >
        <Column width={70} align="center" fixed>
          <HeaderCell>#</HeaderCell>
          <ExpandCell
            dataKey="id"
            expandedRowKeys={expandedRowKeys}
            onChange={handleExpanded}
          />
        </Column>
        <Column
          width={80}
          align="center"
          fixed
          className={`theme-text theme-table-background`}
        >
          <HeaderCell>Id</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column
          width={80}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Device Id </HeaderCell>
          <Cell dataKey="device_id" />
        </Column>
        <Column
          width={100}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Generator Id</HeaderCell>
          <Cell dataKey="generator_id" />
        </Column>
        <Column
          width={130}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Generator Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column
          width={140}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Generator Status</HeaderCell>
          <Cell dataKey="generator_status" />
        </Column>
        <Column
          width={100}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Oil Level</HeaderCell>
          <Cell dataKey="oil_level" />
        </Column>

        <Column
          width={80}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Oil In</HeaderCell>
          <Cell dataKey="oil_in" />
        </Column>
        <Column
          width={80}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Oil Out</HeaderCell>
          <Cell dataKey="oil_out" />
        </Column>
        <Column
          width={80}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Temp</HeaderCell>
          <Cell dataKey="temperature" />
        </Column>
        <Column
          width={125}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Battery Voltage</HeaderCell>
          <Cell dataKey="battery_voltage" />
        </Column>
        <Column
          width={100}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Engine RPM</HeaderCell>
          <Cell dataKey="engine_rpm" />
        </Column>

        <Column
          width={100}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Total Watts</HeaderCell>
          <Cell dataKey="total_watts" />
        </Column>
        <Column
          width={100}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Total VA</HeaderCell>
          <Cell dataKey="total_va" />
        </Column>
        <Column
          width={80}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Full Load</HeaderCell>
          <Cell dataKey="full_power_load" />
        </Column>
        <Column
          width={80}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Full VAR</HeaderCell>
          <Cell dataKey="full_var" />
        </Column>
        <Column
          width={120}
          align="center"
          className={`theme-text theme-table-background`}
          //   flexGrow={1}
        >
          <HeaderCell>Engine Runtime</HeaderCell>
          <Cell dataKey="engine_runtime" />
        </Column>
        <Column
          width={170}
          align="center"
          //   flexGrow={1}
          className={`theme-text theme-table-background`}
        >
          <HeaderCell>Time</HeaderCell>
          <Cell dataKey="created_at" />
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="lg"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalData}
          // total={10}
          limitOptions={[10, 30, 50, 100, 200]}
          limit={limit}
          // activePage={page}
          // onChangePage={setPage}
          activePage={activePage}
          onChangePage={setActivePageHandler}
          onChangeLimit={handleChangeLimit}
          // onChangePage={handlePageChange}
          className={`theme-text`}
        />
      </div>
      {/* <Pagination
        prev
        last
        next
        first
        size="lg"
        total={totalDataCount}
        limit={limit}
        maxButtons={5}
        activePage={activePage}
        onChangePage={setActivePage}
        boundaryLinks
      /> */}
    </div>
  );
};
export default AllGeneratorDataLog;
