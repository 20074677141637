import React from "react";
import GaugeChart from "react-gauge-chart";
import "./gauge.css";

const Gauge = ({ value, unit }) => {
  const chartValue = value / 100;

  return (
    <>
      <div className="gauge-container">
        <GaugeChart
          id="gauge-chart"
          textColor="black"
          nrOfLevels={3}
          percent={chartValue || 0}
          arcsLength={[0.33, 0.33, 0.33]}
          colors={["#41ee9b", "#d1e630", "#e14c4c"]}
          hideText
        />
      </div>
      <div className="value-container">
        <div className="">
          {value} {unit}
        </div>
      </div>
    </>
  );
};

export default Gauge;
