import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useEnergyConsumptionDataForLineChartMutation } from "../../../features/energy-meter/energyMeterUpdatedApi";

const EnergyConsumptionLineChart = ({
  formData,
  graphData,
  timeStampLabel,
  graphName,
  meterType,
  unit,
}) => {
  // console.log(formData);
  // const [graphData, setGraphData] = useState();

  // const [energyConsumptionDataForLineChart, { isLoading }] =
  //   useEnergyConsumptionDataForLineChartMutation();
  // useEffect(() => {
  //   energyConsumptionDataForLineChart(formData)
  //     .unwrap()
  //     .then((payload) => setGraphData(payload))
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [formData]);

  // let ActiveEnergyData = [];
  // let ReactiveEnergyData = [];
  // let ApparentEnergyData = [];
  // let timeStampLabel = [];
  // let currentData = [];

  // if (!isLoading) {
  //   timeStampLabel = graphData?.data?.map((graphData) => {
  //     return graphData?.date;
  //   });
  //   ActiveEnergyData = graphData?.data?.map((graphData) => {
  //     return graphData?.active_energy_consumption;
  //   });
  //   ReactiveEnergyData = graphData?.data?.map((graphData) => {
  //     return graphData?.reactive_energy_consumption;
  //   });
  //   ApparentEnergyData = graphData?.data?.map((graphData) => {
  //     return graphData?.apparent_energy_consumption;
  //   });

  // }

  const data = {
    // labels: ["January", "February", "March", "April", "May"],
    labels: timeStampLabel,

    datasets: [
      {
        label: graphName,
        data: graphData,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      //   y: {
      //     beginAtZero: true,
      //   },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              // Add the unit here based on your data (for example, kWh, kW, etc.)
              label += context.parsed.y + ` ${unit || ""}`; // Replace 'units' with your actual unit
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="chart-title">
        {graphName} Chart(Meter Type :{meterType})
      </div>
      <Line data={data} options={options} height={60} />
    </div>
  );
};

export default EnergyConsumptionLineChart;
