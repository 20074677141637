import React from "react";
import { Line } from "react-chartjs-2";
// import { useGetOilLevelLIneChartDataQuery } from "../../../features/generator/generatorDetailsApi";
import { useParams } from "react-router-dom";

const LineChart = ({chartData,isLoading}) => {
//   const { combined_id } = useParams();
//   const { data: chartData, isLoading } =
//     useGetOilLevelLIneChartDataQuery(combined_id);
  let labels = [];
  let lineChartData = [];
  if (!isLoading) {
    // console.log(chartData);
    let apiData = chartData?.data;
    labels = apiData?.map((chartData) => {
      return chartData?.date;
    });
    lineChartData = apiData?.map((chartData) => {
      return chartData?.used;
    });
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Oil Level",
        data: lineChartData,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      //   y: {
      //     beginAtZero: true,
      //   },
    },
  };

  return (
    <div className="line-chart">
      <Line data={data} options={options} width={"100%"} height={30} />
    </div>
  );
};

export default LineChart;
