import { apiSlice } from "../api/apiSlice";

export const columbiaGeneratorApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generatorModeSwitch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/mode",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGeneratorModeSwitchMutation } = columbiaGeneratorApi;
