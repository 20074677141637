import React from "react";
import Generator from "./assets/generator.png";
import { BsStopwatch } from "react-icons/bs";
import { SlSpeedometer } from "react-icons/sl";
import { CiTempHigh } from "react-icons/ci";
import { BiGasPump } from "react-icons/bi";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";
import { MdWifi } from "react-icons/md";
import { MdWifiOff } from "react-icons/md";

import timeAgo from "../../../helper/timeAgo";
import Timer from "./Timer";
import { useNavigate, generatePath, Link } from "react-router-dom";

function DashboardCard({ data }) {
  let time_ago = "";
  let latestTimeStamp = new Date(data?.updated_at);
  time_ago = timeAgo(latestTimeStamp);
  const navigate = useNavigate();

  const generatorClickHandler = (combined_id) => {
    const path = generatePath("/generator/dashboard/:combined_id", {
      combined_id: combined_id,
    });
    navigate(path);
  };

  return (
    <div>
      <div className="card ">
        <div className="card-header">
          <div>
            {data?.name}({time_ago})
          </div>
          <div
            className="details-text"
            onClick={() => generatorClickHandler(data?.combined_id)}
          >
            <TbListDetails />
            details
          </div>
        </div>
        <div className="card-body  theme-background zero-padding">
          {/* <h5 className="card-title theme-text">Light card title</h5>
          <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p> */}

          <div className="top-portion">
            <div className="generator">
              <img src={Generator} alt="" />
            </div>

            <div className="on-off-portion">
              <div className="on-off-switch">
                {/* <label class="switch">
                  <input
                    type="checkbox"
                    checked={data?.generator_status != 0 ? true : false}
                  />
                  <div class="slider">
                    <span>On</span>
                    <span>Off</span>
                  </div>
                </label> */}
                <div className="wifi-status">
                  {data?.network_status === "Connected" ? (
                    <MdWifi color="green" size="25" />
                  ) : (
                    <MdWifiOff color="red" size="25" />
                  )}
                </div>
                <div>
                  <label class="switch-on-off">
                    <input
                      class="chk"
                      type="checkbox"
                      checked={data?.generator_status == 1 ? true : false}
                    />
                    <span class="slider-on-off"></span>
                  </label>
                </div>
              </div>
              <div className="runtime-section">
                <div className="icon-section">
                  <BsStopwatch size={25} className="icon" />
                </div>
                <div className="runtime-text ">Runtime</div>
                <div className="runtime-value">
                  {" "}
                  {data.generator_status == "1" ? (
                    <Timer time={data?.runtime} />
                  ) : (
                    data?.runtime
                  )}
                </div>
              </div>
              <div className="runtime-section">
                <div className="icon-section">
                  <BsStopwatch size={25} className="icon" />
                </div>
                <div className="runtime-text ">Total Runtime</div>
                <div className="runtime-value">
                  {/* {" "}
                  {data.generator_status == "1" ? (
                    <Timer time={data?.engine_runtime} />
                  ) : (
                    data?.engine_runtime
                  )} */}
                  {data?.engine_runtime}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-portion">
            <div className="bottom-top-portion item-section">
              <div className="runtime-section">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <SlSpeedometer size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">RPM</div>
                </div>
                <div className="runtime-value"> {data?.engine_rpm}</div>
              </div>
              <div className="runtime-section">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <CiTempHigh size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">Temp</div>
                </div>
                <div className="runtime-value"> {data?.temperature}</div>
              </div>
            </div>
            <div className="bottom-bottom-portion item-section">
              <div className="runtime-section  ">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <BiGasPump size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">Oil Level</div>
                </div>
                <div className="runtime-value"> {data?.oil_level}L</div>
              </div>
              <div className="runtime-section">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <AiOutlineThunderbolt size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">Energy</div>
                </div>
                <div className="runtime-value"> {data?.total_watts}kWh</div>
              </div>
            </div>
          </div>
        </div>
        {data?.maintanenece ? (
          <div className="maintenance-alert-container">
            <div className="maintenance-alert">Maintenance Alert</div>
            <div
              className="maintenance-details"
              // style={{ color: "red", padding: "25px 25px" }}
            >
              <Link
                className="maintenance-details"
                to={{
                  pathname: "/generator/maintenance-report",
                  search: `?combined_id=${data?.combined_id}`,
                }}
              >
                details{" "}
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DashboardCard;
