import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import OilLevelOnOffChart from "./OilLevelOnOffChart";
import { useGetGeneratorListQuery } from "../../../features/generator/generatorDataLogApi";
import useTitle from "../../../hooks/useTitle";

function ChartReportsMain() {
  useTitle("Graphical Dashboard");
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [submittedData, setSubmittedData] = useState({});

  const { data: generatorListResponse, isLoading: generatorListLoading } =
    useGetGeneratorListQuery();

  //default timestam and generator select code starts
  const [currentEndDateTime, setCurrentEndDateTime] = useState("");
  const [currentStartDateTime, setCurrentStartDateTime] = useState("");
  const [generatorId, setGeneratorId] = useState("");
  const [hasEffectRun, setHasEffectRun] = useState(false);

  useEffect(() => {
    // Set the default value to the current date and time
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    setCurrentEndDateTime(`${year}-${month}-${day}T${hours}:${minutes}`);
    setCurrentStartDateTime(`${year}-${month}-${day}T00:00`);
  }, []);

  useEffect(() => {
    if (!generatorListLoading) {
      const genUniqueId = generatorListResponse?.data?.map((generatorInfo) => {
        return generatorInfo.unique_id;
      })[0];

      setGeneratorId(genUniqueId);
    }
  }, [generatorListLoading]);

  useEffect(() => {
    if (generatorId && !hasEffectRun) {
      setSubmittedData({
        startdate: currentStartDateTime,
        enddate: currentEndDateTime,
        combined_id: generatorId || "",
      });
      setFormData({
        startdate: currentStartDateTime,
        enddate: currentEndDateTime,
        combined_id: generatorId || "",
      });
      setHasEffectRun(true); //ensures first time execute only 
    }
  }, [currentStartDateTime, generatorId]);
  //default timestam and generator select code ends
  const dataChangeHandler = (e) => {
    if (e.target.name == "combined_id") {
      setGeneratorId(e.target.value);
    }

    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    // console.log(formData);
    setSubmittedData(formData);
  };

  return (
    <div className={`container-fluid ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Graphical Dashboard
      </div>
      <div className={`row theme-text`}>
        <div className="col-md-12 custom-padding">
          <div className="line-chart-container theme-background">
            <div className="chart-title">Oil Level Chart</div>
            <div style={{ paddingLeft: "20px" }}>
              <form onSubmit={(e) => searchSumbitHandler(e)}>
                <div className="row">
                  <div className="form-group col-md-2">
                    <label className="theme-text">Start Date </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="startdate"
                      defaultValue={currentStartDateTime}
                      onChange={dataChangeHandler}
                      required
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label className="theme-text">End Date</label>
                    <input
                      type="datetime-local"
                      class="form-control"
                      name="enddate"
                      defaultValue={currentEndDateTime}
                      onChange={dataChangeHandler}
                      required
                    />
                  </div>
                  <div className="col-md-2 date-search-submit-btn">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="combined_id"
                      value={generatorId}
                      onChange={dataChangeHandler}
                      required
                    >
                      <option value="" selected>
                        Select Generator
                      </option>
                      {generatorListResponse?.data?.map((generatorInfo) => {
                        return (
                          <option value={generatorInfo?.unique_id}>
                            {generatorInfo?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2 date-search-submit-btn">
                    <button type="submit" class="btn btn-primary col-md-12">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="linechart">
              <OilLevelOnOffChart formData={submittedData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartReportsMain;
