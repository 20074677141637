import React, { useState, useEffect } from "react";
import { Scatter } from "react-chartjs-2";
import { useGetOilLevelChartDataMutation } from "../../../features/generator/generatorDetailsApi";
import { useParams } from "react-router-dom";

const MultiColorLineChart = () => {
  const combined_id = useParams();
  // console.log({ combined_id });
  const [getOilLevelChartData, { data: fetchedData, isLoading }] =
    useGetOilLevelChartDataMutation();

  let timestamp;
  let data;

  let colors = [];

  useEffect(() => {
    getOilLevelChartData(combined_id)
      .unwrap()
      .then((payload) => {})
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!isLoading) {
    // console.log(fetchedData);
    timestamp = fetchedData?.data.map((chartData) => {
      return chartData.created_at;
    });
    colors = fetchedData?.data.map((chartData) => {
      return chartData?.generator_status === 0 ? "red" : "green";
    });
    data = fetchedData?.data.map((chartData, index) => ({
      x: index + 1,
      y: chartData?.oil_level,
      color: chartData?.generator_status === 0 ? "red" : "green",
    }));
  }

  // const values = [
  //   11, 9, 7, 8, 7, 11, 13, 12, 7, 12, 11, 9, 7, 8, 7, 11, 13, 12, 7, 12,
  // ];

  // const colors = [
  //   "red",
  //   "red",
  //   "orange",
  //   "orange",
  //   "green",
  //   "green",
  //   "orange",
  //   "red",
  //   "green",
  // ];
  // data = values.map((value, index) => ({
  //   x: index + 1,
  //   y: value,
  //   color: index === 0 ? undefined : colors[index - 1],
  // }));
  // console.log(data);
  const chartData = {
    datasets: [
      {
        label: "Oil Level",
        data: data,
        borderColor: "black",
        showLine: true, // This option is important to show lines connecting the points
        lineTension: 0, // This option ensures straight lines
        fill: false, // Prevents the area under the line from being filled
        pointBackgroundColor: colors,
        pointRadius: 5, // Set the point size
        pointBorderWidth: 2, // Set the point border width
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `Oil Level: ${context.parsed.y} Ltr`,
          // labelColor: (context) => {
          //   return {
          //     borderColor: context.dataset.data[context.dataIndex].color,
          //     backgroundColor: context.dataset.data[context.dataIndex].color,
          //   };
          // },
        },
      },
    },
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    // scales: {
    //   x: {
    //     min: 0,
    //     max: 12,
    //     stepSize: 1,
    //   },
    //   y: {
    //     min: 0,
    //     max: 14,
    //     stepSize: 2,
    //   },
    // },
    scales: {
      x: {
        type: "linear", // Use a linear scale for the X-axis
        position: "bottom",
        title: {
          display: false,
          text: "Timestamp",
        },
        ticks: {
          stepSize: 1,
          // Format the X-axis labels to display non-numeric values
          callback: (value, index) => {
            // Check if nonNumericXValues is defined and has a value at the specified index
            if (timestamp && timestamp[index] !== undefined) {
              return timestamp[index];
            }
            return ""; // Return an empty string or handle the situation as needed
          },
        },
        y: {},
      },
    },
  };

  return (
    <div>
      <Scatter
        data={chartData}
        options={chartOptions}
        width={"100%"}
        height={30}
      />
    </div>
  );
};

export default MultiColorLineChart;
